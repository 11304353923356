import { Link as BaseLink } from '@remix-run/react'
import clsx from 'clsx'
import React from 'react'

type ToLinkProps = React.ComponentProps<typeof BaseLink>
type HrefLinkProps = React.ComponentProps<'a'>

type Props = ToLinkProps | HrefLinkProps

const Link = React.forwardRef<HTMLAnchorElement, Props>(({
  children,
  className,
  // @ts-ignore
  to,
  // @ts-ignore
  href,
  ...props
}, ref) => {
  const classes = clsx('text-blue-600 hover:underline', className)

  if (to) {
    return (
      <BaseLink
        {...props}
        to={to}
        ref={ref}
        className={classes}
      >
        {children}
      </BaseLink>
    )
  }

  return (
    <a
      {...props}
      href={href}
      ref={ref}
      className={classes}
    >
      {children}
    </a>
  )
})

Link.displayName = 'Link'

export default Link
